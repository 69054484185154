import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import styles from './Header.module.scss'
import { useStateValue } from 'state'
import {
  MobileHeader,
  LogoIcon,
  ChevronArrowIcon,
  UserIcon,
  CampIcon,
  SignOutIcon,
  ExternalIcon,
} from 'components'
import { useClickOutside } from 'hooks'
import { getTextInitials } from 'utils'
import { PATH } from 'utils/constants'

const Header = () => {
  const [isOpened, setOpened] = useState(false)
  const {
    state: {
      camp: { activeCamp },
      auth,
      deviceSize,
    },
    action: { userSignOut, getBilling },
  } = useStateValue()
  const { pathname } = useLocation()
  const userRef = useRef(null),
    menuRef = useRef(null)

  useClickOutside([userRef, menuRef], () => setOpened(false))

  useEffect(
    () => (['xs', 'sm', 'md'].includes(deviceSize) ? setOpened(false) : null),
    [deviceSize]
  )

  useEffect(() => setOpened(false), [pathname])

  const goToBilling = () => {
    if (auth.user)
      try {
        getBilling(auth.user.id, (url) => {
          if (url) window.open(url, '_blank').focus()
        })
      } catch (error) {
        console.error(error)
      }
  }
  if (['xs', 'sm', 'md'].includes(deviceSize)) return <MobileHeader />

  return (
    <div className={styles.top_bar__wrapper}>
      <div className={styles.top_bar}>
        <Link className={styles.top_bar__logo} to="/">
          {!pathname.toLowerCase().endsWith('camps') ? (
            activeCamp && activeCamp.logo ? (
              <>
                <img src={activeCamp.logo} alt={activeCamp.name} />
                <h6 className={styles.top_bar__name}>{activeCamp.name}</h6>
              </>
            ) : (
              <h6>camp logo</h6>
            )
          ) : (
            <LogoIcon />
          )}
        </Link>

        <div className={styles.top_bar__menu}>
          {[
            { text: 'Dashboard', to: PATH.DASHBOARD },
            { text: 'Campaigns', to: PATH.CAMPAIGNS },
            { text: 'Ambassadors', to: PATH.AMBASSADORS },
            { text: 'Prospects', to: PATH.PROSPECTS },
            { text: 'Help', to: 'https://help.camptree.org/' },
          ].map(({ text, to }, index) => (
            <Link
              className={clsx(
                styles.top_bar__menu__item,
                pathname.startsWith(to) && styles.top_bar__menu__item__active
              )}
              to={to}
              dangerouslySetInnerHTML={{ __html: text }}
              key={index}
              target={text === 'Help' ? '_blank' : undefined}
            />
          ))}
        </div>

        <div
          ref={userRef}
          className={styles.user}
          onClick={() => setOpened(!isOpened)}
        >
          <div className={styles.user__avatar}>
            {getTextInitials(auth.user.name)}
            <span className={styles.hide}></span>
          </div>

          <span className={styles.user__name}>{auth.user.name}</span>

          <ChevronArrowIcon
            className={clsx(
              styles.user__arrow,
              !isOpened && styles.user__arrow__down
            )}
          />
        </div>

        <div
          ref={menuRef}
          className={clsx(
            styles.user__menu,
            isOpened && styles.user__menu__opened
          )}
        >
          {[
            {
              Icon: UserIcon,
              text: 'User Profile',
              to: PATH.AUTH__YOUR_PROFILE,
            },
            // { Icon: AlertIcon, text: 'Notifications', to: PATH.NOTIFICATIONS },
            { Icon: CampIcon, text: 'My Camps', to: PATH.CAMPS },
          ].map(({ Icon, text, to }, mIdx) => (
            <Link key={mIdx} className={styles.user__menu__item} to={to}>
              <Icon className={styles.user__menu__item__icon} />
              <span className={styles.user__menu__item__text}>{text}</span>
            </Link>
          ))}

          <a className={styles.user__menu__item} onClick={goToBilling}>
            <ExternalIcon className={styles.user__menu__item__icon} />
            <span className={styles.user__menu__item__text}>Billing</span>
          </a>

          <button
            type="button"
            className={clsx(
              styles.user__menu__item,
              styles.user__menu__item__logout
            )}
            onClick={userSignOut}
          >
            <SignOutIcon className={styles.user__menu__item__icon} />
            <span
              className={clsx(
                styles.user__menu__item__text,
                styles.user__menu__item__logout_text
              )}
            >
              Logout
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
